<template>
  <div class="">
    <h2>รูปกำลังตรวจสอบ</h2>
    <div v-if="!loading">
      <b-row>
        <b-col sm="6" md="4" lg="3" v-for="(item, index) in listData" :key="item.id" class="mt-2">
          <ImageActionCard
            :item="item"
            @onClickMoreTransactionReview="onClickMoreTransactionReview"
            @onClickPreviewImage="onClickPreviewImage"
            @onClickMoreDetailsImage="onClickMoreDetailsImage"
          />
          <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
        </b-col>
      </b-row>
      <h2 v-if="listData.length <= 0" class="text-center text-muted mt-5">
        ยังไม่มีรูปที่กำลังตรวจสอบตอนนี้
      </h2>
    </div>

    <BoxContainerLoading :loading="loading" />
    <b-modal
      id="modal-preview-images"
      ref="modal-preview-images"
      size="xl"
      hide-footer
      body-class="p-0"
      title="รูปทั้งหมด"
    >
      <ImagesPreviewSlide :listData="imagePreviewList" />
    </b-modal>

    <b-modal id="modal-review-transaction" ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
      <TimelineReviewWidget :listData="listReview" />
    </b-modal>
  </div>
</template>

<script>
import ImageActionCard from '../components/ImageActionCard.vue'
import TimelineReviewWidget from '../../../../../widgets/TimelineReviewWidget.vue'
import ImagesPreviewSlide from '../components/ImagesPreviewSlide.vue'
import BoxContainerLoading from '@/widgets/BoxContainerLoading.vue'

export default {
  components: { ImageActionCard, TimelineReviewWidget, ImagesPreviewSlide, BoxContainerLoading },
  data() {
    return {
      listData: [],
      listReview: [],
      imagePreviewList: [],
      loading: false,
    }
  },
  async created() {
    this.loading = true
    const res = await this.api.get('api/admin/admin-image-approve?status=checking&filterType=image')
    // console.log('[RES]: getImageChecking', res)
    this.listData = res
    this.loading = false
  },
  methods: {
    onClickMoreTransactionReview(data) {
      // console.log('onClickMoreTransactionReview => ', data)
      this.listReview = [...data]
      this.$refs['modal-review-transaction'].show()
    },
    onClickPreviewImage(data) {
      // console.log('onClickPreviewImage => ', data)
      this.imagePreviewList = data
      this.$refs['modal-preview-images'].show()
    },
    onClickMoreDetailsImage(data) {
      // console.log('onClickMoreDetailsImage => ', data)
      this.$router.push({ name: 'images-management-details', params: { id: data.id ?? 0, canReview: true } })
    },
  },
}
</script>
